import { template as template_5a2e7a249b424e5c9bcdfc43ca094d5b } from "@ember/template-compiler";
const FKControlMenuContainer = template_5a2e7a249b424e5c9bcdfc43ca094d5b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
