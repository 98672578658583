import { template as template_7ba60024455a4716b0fa1f28b90e9481 } from "@ember/template-compiler";
const FKText = template_7ba60024455a4716b0fa1f28b90e9481(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
